export const IconMaskLeft = props => (
  <svg
    viewBox="0 0 10 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_66_807"
      style={{
        maskType: 'alpha'
      }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="10"
      height="20"
    >
      <rect width="10" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_66_807)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0H0V10V20H10H20V10V0H10ZM10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0Z"
        fill="currentColor"
      />
    </g>
  </svg>
)
