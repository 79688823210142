export function waterfall(list = [], columnLength, calcHeight) {
  const result = Array.from({ length: columnLength }).map(() => [])
  const heights = Array.from({ length: columnLength }).fill(0) as number[]

  list.forEach((row, index) => {
    const rowHeight = calcHeight(row, index)
    const minHeight = Math.min(...heights)
    const k = heights.indexOf(minHeight)

    heights[k] += rowHeight
    result[k].push(row)
  })

  return result
}
