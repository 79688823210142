export const TwitterIcon = props => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle opacity="0.24" cx="17" cy="17" r="16" stroke="#000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3253 12.2102C21.9866 12.4272 21.2089 12.7424 20.8254 12.7424V12.7431C20.3875 12.2856 19.771 12 19.0872 12C17.7592 12 16.6823 13.0769 16.6823 14.4044C16.6823 14.5888 16.7037 14.7687 16.7432 14.9412H16.7428C14.9414 14.894 12.9712 13.991 11.7852 12.4457C11.056 13.7079 11.687 15.1117 12.5145 15.6232C12.2313 15.6445 11.7098 15.5905 11.4643 15.351C11.4479 16.1888 11.8507 17.2988 13.3195 17.7015C13.0366 17.8537 12.5359 17.81 12.3182 17.7777C12.3946 18.4849 13.3849 19.4094 14.4679 19.4094C14.0819 19.8559 12.6297 20.6657 11 20.408C12.1068 21.0814 13.3967 21.4714 14.762 21.4714C18.6419 21.4714 21.655 18.327 21.4929 14.4479C21.4922 14.4436 21.4922 14.4393 21.4918 14.4347C21.4922 14.4247 21.4929 14.4147 21.4929 14.4044C21.4929 14.3923 21.4918 14.3809 21.4914 14.3692C21.8446 14.1277 22.3186 13.7004 22.6571 13.138C22.4608 13.2461 21.8717 13.4627 21.3236 13.5165C21.6754 13.3266 22.1966 12.7047 22.3253 12.2102Z"
      fill="black"
    />
  </svg>
)
