export function Responsive() {
  return (
    <div className="relative bg-slate-900 py-24 px-4 sm:px-6 overflow-hidden">
      <div className="max-w-8xl mx-auto">
        <div className="grid gap-[4vmin] grid-cols-12 items-center">
          <div className="flex justify-center col-span-12 md:col-span-7 h-auto md:h-[800px] relative">
            <div className="relative h-full w-full overflow-hidden bg-[#F8B1E2] rounded-lg md:rounded-xl lg:rounded-2xl flex flex-col justify-center">
              <img
                className="md:w-full md:h-full md:block"
                src="/static/responsive.webp"
                alt="Responsive forms"
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-5 mt-12">
            <div className="text-xs text-blue-300 uppercase tracking-widest flex justify-between items-center">
              <span className="flex-shrink-0">Make it work anywhere</span>
            </div>
            <h2 className="mt-6 text-2xl lg:text-5xl font-bold text-white leading-tight">
              Capture data on any device, anytime.
            </h2>
            <p className="mt-6 text-xl lg:text-2xl text-slate-300">
              HeyForm makes it easy to build responsive forms that work
              seamlessly on any screen—whether it’s mobile, tablet, or desktop.
              Your forms automatically adapt to fit each device, ensuring better
              accessibility, higher completion rates, and valuable data from
              every user.
            </p>
            <p className="mt-6 text-xl lg:text-2xl text-slate-300">
              Let HeyForm handle the tech, so you can focus on gathering the
              insights that matter.
            </p>
            <a
              className="block mt-6 text-xl lg:text-2xl text-blue-300 font-medium"
              href="https://my.heyform.net/sign-up"
            >
              Build responsive forms today →
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
