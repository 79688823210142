export const IconLove = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.09117 3.0111C9.95536 1.91796 11.1992 1.20708 12.567 1.02455C13.9349 0.842025 15.3229 1.20171 16.4458 2.02969C17.5676 2.85671 18.3401 4.08829 18.6047 5.47198C18.8693 6.85566 18.606 8.28663 17.8689 9.47155L11.5877 18.4025L2.80587 11.9166C1.73062 11.0253 1.02762 9.75003 0.841188 8.35251C0.654755 6.95499 0.999039 5.5413 1.80336 4.40167C2.60768 3.26205 3.811 2.48297 5.16628 2.22437C6.52157 1.96577 7.92597 2.24728 9.09117 3.0111ZM15.3962 3.51645C14.6481 2.96536 13.7238 2.72594 12.8128 2.84731C11.9018 2.96868 11.0733 3.44163 10.4971 4.16914L9.48782 5.44906L8.12474 4.55516C7.35124 4.04898 6.41987 3.86133 5.52016 4.03041C4.62045 4.19949 3.82001 4.71259 3.28171 5.46529C2.74341 6.218 2.50771 7.15376 2.62257 8.08214C2.73743 9.01053 3.19421 9.86179 3.89998 10.4627L11.1715 15.8326L16.3712 8.43926C16.8487 7.64944 17.0136 6.70208 16.8326 5.7884C16.6516 4.87472 16.1383 4.06277 15.3962 3.51645Z"
      fill={props.color}
    />
  </svg>
)
