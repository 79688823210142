import React, { useEffect, useRef } from 'react'
// import { useTranslation } from '@/utils/hook'
import Image from 'next/image'

export function Brand() {
  // const t = useTranslation()
  const scrollContainerRef = useRef(null)
  const brands = [
    { src: '/static/customers/bytedance.svg', alt: 'Bytedance' },
    { src: '/static/customers/airbnb.svg', alt: 'Airbnb' },
    { src: '/static/customers/nyu.svg', alt: 'New York University' },
    { src: '/static/customers/realadvisor.svg', alt: 'RealAdvisor' },
    { src: '/static/customers/umich.svg', alt: 'University of Michigan' },
    { src: '/static/customers/hope.svg', alt: 'Hope College' },
    { src: '/static/customers/gameskraft.webp', alt: 'Gameskraft' },
    { src: '/static/customers/esimple.png', alt: 'Esimple' },
    { src: '/static/customers/wizishop.png', alt: 'Wizishop' },
    { src: '/static/customers/aria.webp', alt: 'Aria' }
  ]

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current
    const scrollSpeed = 1.5 // 控制滚动速度
    let position = 0
    let animationFrameId // 用来存储 requestAnimationFrame 的返回值

    const animateScroll = () => {
      position += scrollSpeed
      if (position >= scrollContainer.scrollWidth / 2) {
        position = 0 // 重置位置
      }
      scrollContainer.style.transform = `translateX(-${position}px)`
      animationFrameId = requestAnimationFrame(animateScroll) // 捕获返回的 animationFrameId
    }

    animateScroll()

    // 清理动画帧
    return () => {
      cancelAnimationFrame(animationFrameId) // 使用 animationFrameId 来取消动画
    }
  }, [])

  return (
    <div className="pb-16">
      <div className="brandWrap">
        <div className="px-4 sm:px-6">
          <div className="overflow-hidden whitespace-nowrap">
            <div className="flex" ref={scrollContainerRef}>
              <div className="flex">
                {/* 渲染 logo */}
                {brands.map((brand, index) => (
                  <Image
                    key={index}
                    src={brand.src}
                    alt={brand.alt}
                    width={160}
                    height={96}
                    className="w-40 h-24 mx-8 object-contain filter grayscale hover:filter-none transition duration-300"
                  />
                ))}
                {/* 复制内容以实现无缝滚动 */}
                {brands.map((brand, index) => (
                  <Image
                    key={`copy-${index}`}
                    src={brand.src}
                    alt={brand.alt}
                    width={160}
                    height={96}
                    className="w-40 h-24 mx-8 object-contain filter grayscale hover:filter-none transition duration-300"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
