export const IconApple = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 35 35"
    fill={props.color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.096 0.952515C11.9688 0.952515 10.8878 1.40028 10.0908 2.19731C9.29375 2.99434 8.84598 4.07534 8.84598 5.20251V8.23823H5.81027C4.52207 8.23823 3.28664 8.74996 2.37575 9.66085C1.46486 10.5717 0.953125 11.8072 0.953125 13.0954V30.0954C0.953125 31.3836 1.46486 32.619 2.37575 33.5299C3.28664 34.4408 4.52207 34.9525 5.81027 34.9525H30.096C31.3842 34.9525 32.6196 34.4408 33.5305 33.5299C34.4414 32.619 34.9531 31.3836 34.9531 30.0954V13.0954C34.9531 11.8072 34.4414 10.5717 33.5305 9.66085C32.6196 8.74996 31.3842 8.23823 30.096 8.23823H27.0603V5.20251C27.0603 4.07534 26.6125 2.99434 25.8155 2.19731C25.0184 1.40028 23.9374 0.952515 22.8103 0.952515H13.096ZM23.4174 8.23823V5.20251C23.4174 5.04149 23.3534 4.88706 23.2396 4.7732C23.1257 4.65934 22.9713 4.59537 22.8103 4.59537H13.096C12.935 4.59537 12.7805 4.65934 12.6667 4.7732C12.5528 4.88706 12.4888 5.04149 12.4888 5.20251V8.23823H23.4174ZM5.81027 11.8811H30.096C30.418 11.8811 30.7269 12.009 30.9546 12.2367C31.1823 12.4645 31.3103 12.7733 31.3103 13.0954V15.5239H4.59598V13.0954C4.59598 12.7733 4.72392 12.4645 4.95164 12.2367C5.17936 12.009 5.48822 11.8811 5.81027 11.8811ZM4.59598 19.1668V30.0954C4.59598 30.4174 4.72392 30.7263 4.95164 30.954C5.17936 31.1817 5.48822 31.3097 5.81027 31.3097H30.096C30.418 31.3097 30.7269 31.1817 30.9546 30.954C31.1823 30.7263 31.3103 30.4174 31.3103 30.0954V19.1668H20.3817V22.8097H15.5246V19.1668H4.59598Z"
    />
  </svg>
)
