import React, { useEffect, useRef } from 'react'

export function ImageGallery() {
  const scrollContainerRef = useRef(null)
  const images = [
    { src: '/static/examples/form-showcase-1.webp', alt: 'HeyForm Example 1' },
    { src: '/static/examples/form-showcase-2.webp', alt: 'HeyForm Example 2' },
    { src: '/static/examples/form-showcase-3.webp', alt: 'HeyForm Example 3' },
    { src: '/static/examples/form-showcase-4.webp', alt: 'HeyForm Example 4' },
    { src: '/static/examples/form-showcase-5.webp', alt: 'HeyForm Example 5' },
    { src: '/static/examples/form-showcase-6.webp', alt: 'HeyForm Example 6' },
    { src: '/static/examples/form-showcase-7.webp', alt: 'HeyForm Example 7' },
    {
      src: '/static/examples/form-showcase-8.webp',
      alt: 'HeyForm Example 8'
    },
    {
      src: '/static/examples/form-showcase-9.webp',
      alt: 'HeyForm Example 9'
    },
    {
      src: '/static/examples/form-showcase-10.webp',
      alt: 'HeyForm Example 10'
    }
  ]

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current
    const scrollSpeed = 1.5 // 控制滚动速度
    let position = 0
    let animationFrameId

    const animateScroll = () => {
      position += scrollSpeed
      if (position >= scrollContainer.scrollWidth / 2) {
        position = 0 // 重置位置
      }
      scrollContainer.style.transform = `translateX(-${position}px)`
      animationFrameId = requestAnimationFrame(animateScroll)
    }

    animateScroll()

    return () => {
      cancelAnimationFrame(animationFrameId)
    }
  }, [])

  return (
    <div className="mt-2 md:mt-24">
      <div className="">
        <div className="overflow-hidden whitespace-nowrap">
          <div className="flex" ref={scrollContainerRef}>
            {/* 渲染图片 */}
            <div className="flex">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image.src}
                  alt={image.alt}
                  className="w-96 h-56 mx-2 object-contain transition duration-300 rounded-t-xl"
                />
              ))}
              {/* 复制内容以实现无缝滚动 */}
              {images.map((image, index) => (
                <img
                  key={`copy-${index}`}
                  src={image.src}
                  alt={image.alt}
                  className="w-96 h-56 mx-2 object-contain transition duration-300"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageGallery

export function BrandedForms() {
  return (
    <div className="relative bg-slate-900 overflow-hidden pt-24">
      <div className="max-w-7xl mx-auto px-4 md:px-0">
        <div className="grid gap-[8vmin] grid-cols-12 items-center">
          <div className="col-span-12 lg:col-span-5 mt-12">
            <div className="text-xs text-blue-300 uppercase tracking-widest flex justify-between items-center">
              <span className="flex-shrink-0">Make it unique</span>
            </div>
            <h2 className="mt-6 text-2xl lg:text-5xl font-bold text-white font-hdg">
              Stand out from the crowd with unique, branded forms
            </h2>
            <p className="mt-6 text-xl lg:text-2xl text-slate-300 font-hdg">
              Create forms that truly reflect your brand’s personality and
              style. Customize everything—from fonts and colors to logos and
              backgrounds—ensuring a cohesive brand experience for your
              audience.
            </p>
            <p className="mt-6 text-xl lg:text-2xl text-slate-300 font-hdg">
              Leave behind the generic, and let your brand take center stage
              while HeyForm handles the tech.
            </p>
            <a
              className="inline-block mt-6 text-xl lg:text-2xl text-blue-300 font-semibold font-hdg hover:underline"
              href="https://my.heyform.net/sign-up"
            >
              Create your forms today →
            </a>
          </div>

          <div className="col-span-7">
            <div className="w-screen h-auto mt-12">
              <div className="max-w-7xl rounded-lg md:rounded-xl lg:rounded-2xl overflow-hidden bg-white">
                <img
                  src="/static/customization.webp"
                  alt="HeyForm Customization"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ImageGallery />
    </div>
  )
}
