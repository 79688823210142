export const IconMedium = props => (
  <svg
    width="36"
    height="20"
    viewBox="0 0 36 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9.93201 0C15.4173 0 19.8637 4.4774 19.8637 10.0002C19.8637 15.5229 15.417 20 9.93201 20C4.44703 20 0 15.5229 0 10.0002C0 4.4774 4.44669 0 9.93201 0ZM25.7927 0.585784C28.5354 0.585784 30.7587 4.80032 30.7587 10.0002H30.7591C30.7591 15.1987 28.5357 19.4146 25.7931 19.4146C23.0504 19.4146 20.827 15.1987 20.827 10.0002C20.827 4.80167 23.0501 0.585784 25.7927 0.585784ZM33.469 1.56636C34.4334 1.56636 35.2155 5.34224 35.2155 10.0002C35.2155 14.6567 34.4337 18.434 33.469 18.434C32.5043 18.434 31.7228 14.6578 31.7228 10.0002C31.7228 5.34258 32.5046 1.56636 33.469 1.56636Z" />
  </svg>
)
