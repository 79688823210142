export const FacebookIcon = props => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle opacity="0.24" cx="17" cy="17" r="16" stroke="#000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0933 23.0124H17.2789V17.0091H18.9185L19.2428 15.1333H17.2789V13.7741C17.2789 13.3359 17.5623 12.8758 17.9672 12.8758H19.0833V11H17.7155V11.0084C15.5752 11.0861 15.1355 12.3256 15.0973 13.6276H15.0933V15.1333H14V17.0091H15.0933V23.0124Z"
      fill="black"
    />
  </svg>
)
