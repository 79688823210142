import React from 'react'

// 单个集成项组件
const IntegrationItem = ({
  href,
  imgSrc,
  imgAlt,
  description,
  imgClass,
  pClass
}) => {
  return (
    <div className="group relative flex flex-col items-center justify-center">
      <a
        className={`w-24 h-24 flex flex-col items-center justify-center rounded-3xl hover:shadow-3xl border border-neutral-200 overflow-hidden shadow-sm pointer-events-auto ${imgClass}`}
        href={href}
      >
        <img
          src={imgSrc}
          alt={imgAlt}
          width="160"
          height="160"
          loading="lazy"
        />
      </a>
      <p
        className={`absolute text-xs leading-none text-white px-3 py-2 bg-zinc-900 rounded-lg shadow-sm pointer-events-none whitespace-nowrap bottom-full opacity-0 transition-all group-hover:opacity-100 group-hover:bottom-[110%] ${pClass}`}
      >
        {description}
      </p>
    </div>
  )
}

// 主组件，渲染多个集成项
const IntegrationsList = () => {
  const integrationsGrouped = [
    [
      {
        imgSrc: '/static/integrations/freshdesk.png',
        imgAlt: 'Freshdesk',
        description: 'Freshdesk',
        imgClass: '',
        pClass: ''
      },
      {
        imgSrc: '/static/integrations/coda.webp',
        imgAlt: 'Coda',
        description: 'Coda',
        imgClass: '',
        pClass: ''
      },
      {
        imgSrc: '/static/integrations/zendesk.png',
        imgAlt: 'Zendesk',
        description: 'Zendesk',
        imgClass: '',
        pClass: ''
      }
    ],
    [
      {
        imgSrc: '/static/integrations/supportpal.png',
        imgAlt: 'SupportPal',
        description: 'SupportPal',
        imgClass: 'p-4',
        pClass: ''
      },
      {
        href: 'https://docs.heyform.net/integrations/lark-suite',
        imgSrc: '/static/integrations/lark.png',
        imgAlt: 'Lark Suite',
        description: 'Lark Suite',
        imgClass: 'p-4',
        pClass: ''
      },
      {
        imgSrc: '/static/integrations/pipedream.png',
        imgAlt: 'Pipedream',
        description: 'Pipedream',
        imgClass: '',
        pClass: ''
      },
      {
        imgSrc: '/static/integrations/trello.png',
        imgAlt: 'Trello',
        description: 'Trello',
        imgClass: '',
        pClass: ''
      }
    ],
    [
      {
        href: 'https://docs.heyform.net/integrations/telegram',
        imgSrc: '/static/integrations/telegram.png',
        imgAlt: 'Telegram',
        description: 'Telegram',
        imgClass: '',
        pClass: ''
      },
      {
        href: 'https://docs.heyform.net/integrations/email',
        imgSrc: '/static/integrations/email.webp',
        imgAlt: 'Email',
        description: 'Email',
        imgClass: 'p-4',
        pClass: ''
      },
      {
        href: 'https://docs.heyform.net/integrations/webhook',
        imgSrc: '/static/integrations/webhook.png',
        imgAlt: 'Webhook',
        description: 'Webhook',
        imgClass: '',
        pClass: ''
      }
    ],
    [
      {
        imgSrc: '/static/integrations/monday.png',
        imgAlt: 'Monday',
        description: 'Monday',
        imgClass: '',
        pClass: ''
      },
      {
        href: 'https://docs.heyform.net/integrations/hubspot',
        imgSrc: '/static/integrations/hubspot.png',
        imgAlt: 'Hubspot',
        description: 'Hubspot',
        imgClass: '',
        pClass: ''
      }
    ],
    [
      {
        href: 'https://docs.heyform.net/integrations/google-drive',
        imgSrc: '/static/integrations/google-drive.png',
        imgAlt: 'Google Drive',
        description: 'Google Drive',
        imgClass: '',
        pClass: ''
      },
      {
        href: 'https://docs.heyform.net/integrations/airtable',
        imgSrc: '/static/integrations/airtable.png',
        imgAlt: 'Airtable',
        description: 'Airtable',
        imgClass: '',
        pClass: ''
      }
    ],
    [
      {
        imgSrc: '/static/integrations/dropbox.png',
        imgAlt: 'Dropbox',
        description: 'Dropbox',
        imgClass: '',
        pClass: ''
      }
    ],
    [
      {
        href: 'https://docs.heyform.net/integrations/mailchimp',
        imgSrc: '/static/integrations/mailchimp.webp',
        imgAlt: 'Mailchimp',
        description: 'Mailchimp',
        imgClass: '',
        pClass: ''
      }
    ],
    [
      {
        imgSrc: '/static/integrations/stripe.png',
        imgAlt: 'Stripe',
        description: 'Stripe',
        imgClass: '',
        pClass: ''
      }
    ],
    [
      {
        href: 'https://docs.heyform.net/integrations/slack',
        imgSrc: '/static/integrations/slack.png',
        imgAlt: 'Slack',
        description: 'Slack',
        imgClass: '',
        pClass: ''
      }
    ],
    [
      {
        href: 'https://docs.heyform.net/integrations/notion',
        imgSrc: '/static/integrations/notion.svg',
        imgAlt: 'Notion',
        description: 'Notion',
        imgClass: 'p-4',
        pClass: ''
      }
    ],
    [
      {
        imgSrc: '/static/integrations/n8n.png',
        imgAlt: 'N8N',
        description: 'N8N',
        imgClass: '',
        pClass: ''
      }
    ],
    [
      {
        href: 'https://docs.heyform.net/integrations/zapier',
        imgSrc: '/static/integrations/zapier.png',
        imgAlt: 'Zapier',
        description: 'Zapier',
        imgClass: '',
        pClass: ''
      },
      {
        imgSrc: '/static/integrations/google-tag-manager.png',
        imgAlt: 'Google Tag Manager',
        description: 'Google Tag Manager',
        imgClass: '',
        pClass: ''
      }
    ],
    [
      {
        href: 'https://docs.heyform.net/integrations/facebook-pixel',
        imgSrc: '/static/integrations/facebook-pixel.webp',
        imgAlt: 'Facebook Pixel',
        description: 'Facebook Pixel',
        imgClass: '',
        pClass: ''
      },
      {
        imgSrc: '/static/integrations/osticket.png',
        imgAlt: 'osTicket',
        description: 'osTicket',
        imgClass: '',
        pClass: ''
      }
    ],
    [
      {
        href: 'https://docs.heyform.net/integrations/google-analytics',
        imgSrc: '/static/integrations/google-analytics.webp',
        imgAlt: 'Google Analytics',
        description: 'Google Analytics',
        imgClass: 'p-4',
        pClass: ''
      },
      {
        imgSrc: '/static/integrations/make.png',
        imgAlt: 'Make',
        description: 'Make',
        imgClass: '',
        pClass: ''
      },
      {
        href: 'https://docs.heyform.net/integrations/google-sheets',
        imgSrc: '/static/integrations/google-sheet.png',
        imgAlt: 'Google Sheets',
        description: 'Google Sheets',
        imgClass: '',
        pClass: ''
      }
    ],
    [
      {
        imgSrc: '/static/integrations/wordpress.png',
        imgAlt: 'WordPress',
        description: 'WordPress',
        imgClass: '',
        pClass: ''
      },
      {
        imgSrc: '/static/integrations/excel.png',
        imgAlt: 'Excel',
        description: 'Excel',
        imgClass: 'p-4',
        pClass: ''
      },
      {
        imgSrc: '/static/integrations/asana.png',
        imgAlt: 'Asana',
        description: 'Asana',
        imgClass: 'p-4',
        pClass: ''
      },
      {
        imgSrc: '/static/integrations/activecampaign.png',
        imgAlt: 'ActiveCampaign',
        description: 'ActiveCampaign',
        imgClass: '',
        pClass: ''
      }
    ],
    [
      {
        href: '#',
        imgSrc: '/static/integrations/google-calendar.svg',
        imgAlt: 'Google Calendar',
        description: 'Google Calendar',
        imgClass: '',
        pClass: ''
      },
      {
        href: '#',
        imgSrc: '/static/integrations/sms.svg',
        imgAlt: 'SMS',
        description: 'SMS',
        imgClass: '',
        pClass: ''
      },
      {
        href: '#',
        imgSrc: '/static/integrations/pdfmonkey.png',
        imgAlt: 'PDFMonkey',
        description: 'PDFMonkey',
        imgClass: '',
        pClass: ''
      }
    ]
  ]

  const liClasses = [
    'flex-shrink-0 flex flex-col gap-12 mt-10',
    'flex-shrink-0 flex flex-col gap-12 -mt-10',
    'flex-shrink-0 flex flex-col gap-12 mt-10',
    'flex-shrink-0 flex flex-col gap-12 mt-20',
    'flex-shrink-0 flex flex-col gap-12 mt-10',
    'flex-shrink-0 flex flex-col gap-12 mt-20',
    'flex-shrink-0 mt-10',
    'flex-shrink-0',
    'flex-shrink-0',
    'flex-shrink-0 mt-10',
    'flex-shrink-0 flex flex-col gap-12 mt-20',
    'flex-shrink-0 flex flex-col gap-12 mt-10',
    'flex-shrink-0 flex flex-col gap-12 mt-20',
    'flex-shrink-0 flex flex-col gap-12 mt-10',
    'flex-shrink-0 flex flex-col gap-12 -mt-10',
    'flex-shrink-0 flex flex-col gap-12 mt-10'
  ]

  return (
    <ul className="scale-75 lg:scale-100 py-10 flex-shrink-0 flex justify-center gap-12 -mb-80 pointer-events-none">
      {integrationsGrouped.map((group, index) => (
        <li key={index} className={liClasses[index]}>
          {group.map((integration, i) => (
            <IntegrationItem
              key={i}
              href={integration.href}
              imgSrc={integration.imgSrc}
              imgAlt={integration.imgAlt}
              description={integration.description}
              imgClass={integration.imgClass}
              pClass={integration.pClass}
            />
          ))}
        </li>
      ))}
    </ul>
  )
}

export function Integrations() {
  return (
    <div className="relative flex flex-col items-center bg-white py-32 px-4 sm:px-6 overflow-hidden">
      <IntegrationsList />
      <div className="max-w-8xl mx-auto">
        <div className="grid gap-[4vmin] grid-cols-12 items-center">
          <div className="col-span-12 text-center">
            <h2 className="text-xs text-slate-500 uppercase tracking-widest">
              Integrations
            </h2>
            <p className="mt-6 text-4xl font-bold text-black font-hdg leading-[0.9]">
              Connect the dots.
            </p>
            <p className="max-w-2xl mx-auto mt-6 text-xl lg:text-2xl leading-normal font-normal text-slate-700 font-hdg">
              Streamline your workflow with seamless integration of your
              everyday tools - reporting, payment gateways, analytics,
              marketing, automation. <b>Everything!</b>
            </p>
            <a
              className="inline-block mt-6 text-xl text-slate-500 font-semibold font-hdg hover:underline"
              href="/integrations/"
            >
              Explore the integrations library →
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
