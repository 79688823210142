import Image from 'next/image'
import { useMemo } from 'react'
import { useTranslation } from '@/utils/hook'

import {
  IconProducthunt,
  IconX,
  IconLinkedin,
  IconTrustpilot
} from '@/components/icons'
import { waterfall } from '@/utils/waterfall'

const TestimonialIcon = ({ platform }) => {
  switch (platform) {
    case 'X':
      return <IconX className="h-6 w-6 fill-black" />

    case 'Product Hunt':
      return <IconProducthunt className="h-6 w-6" />

    case 'Linkedin':
      return <IconLinkedin className="h-6 w-6" />

    case 'Trustpilot':
      return <IconTrustpilot className="h-6 w-6" />

    default:
      return null
  }
}

const TestimonialSocial = ({ testimonial }) => {
  return (
    <div className="w-full rounded-2xl border border-slate-400/10 bg-white p-8 shadow-sm shadow-gray-900/5">
      <div className="flex w-full items-center">
        <Image
          className="h-12 w-12 rounded-full bg-slate-50"
          src={testimonial.avatar}
          width="48"
          height="48"
          alt={testimonial.name}
        />
        <div className="flex-1 flex-grow pl-3">
          <div className="my-auto flex justify-between">
            <div>
              <div className="text-md inline font-bold">
                <a
                  className="hover:underline !text-black"
                  href={testimonial.source}
                  target="_blank"
                  rel="noreferrer"
                >
                  {testimonial.name}
                </a>
              </div>
              <div className="text-sm text-slate-500">{testimonial.title}</div>
            </div>
            <a
              href={testimonial.source}
              target="_blank"
              rel="noreferrer"
              aria-label={testimonial.platform}
            >
              <TestimonialIcon platform={testimonial.platform} />
            </a>
          </div>
        </div>
      </div>

      <div className="mt-4 w-full flex-grow">
        <div className="text-md text-slate-700">
          <div dangerouslySetInnerHTML={{ __html: testimonial.testimonial }} />
        </div>
      </div>

      <div className="mt-2 flex w-full justify-between">
        <div className="mt-2 text-sm">
          <a
            className="hover:underline !text-slate-500"
            href={testimonial.source}
            target="_blank"
            rel="noreferrer"
          >
            {testimonial.date}
          </a>
        </div>
      </div>
    </div>
  )
}

const TestimonialEmail = ({ testimonial }) => {
  return (
    <div className="w-full rounded-2xl border border-slate-400/10 bg-white p-8 shadow-sm shadow-gray-900/5">
      <div className="flex w-full items-center">
        <div>
          <Image
            className="h-12 w-12 rounded-full"
            src={testimonial.avatar}
            width="48"
            height="48"
            alt={testimonial.name}
          />
        </div>
        <div className="flex-1 flex-grow pl-3">
          <div className="my-auto flex justify-between">
            <div>
              <div className="text-md inline font-bold text-slate-950">
                {testimonial.name}
              </div>
              <div className="text-sm text-slate-500 ">{testimonial.title}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 w-full flex-grow">
        <div className="text-md text-slate-700">
          <div dangerouslySetInnerHTML={{ __html: testimonial.testimonial }} />
        </div>
      </div>

      <div className="mt-2 flex w-full justify-between">
        <div className="mt-2 text-sm !text-slate-500">{testimonial.date}</div>
      </div>
    </div>
  )
}

export const TestimonialItem = ({ testimonial }) => {
  switch (testimonial.platform) {
    case 'X':
    case 'Product Hunt':
    case 'Linkedin':
    case 'Trustpilot':
      return <TestimonialSocial testimonial={testimonial} />
    case 'Email':
      return <TestimonialEmail testimonial={testimonial} />
    default:
      return null
  }
}

export const Testimonials = ({ testimonials }) => {
  const t = useTranslation()

  const columns = useMemo(
    () =>
      waterfall(testimonials, 3, row => row.testimonial.length || 0).map(
        row => [...row, ...row]
      ),
    [testimonials]
  )

  return (
    <section id="testimonials0" className="py-12 md:py-24">
      <div className="mx-auto max-w-5xl px-5 py-12 max-[1280px]:max-w-6xl md:mt-10 lg:px-0">
        <h2 className="sectionTitle">{t('home.testimonialTitle')}</h2>
        <p className="sectionDesc">{t('home.testimonialDesc')}</p>

        <div className="testimonials relative -mx-4 mt-12 grid h-[49rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-hidden px-4 sm:mt-20 md:grid-cols-2 lg:grid-cols-3">
          {columns.map((list, index) => (
            <div
              key={index}
              className={`testimonial-column testimonial-column-${index} flex flex-col gap-y-6 sm:gap-y-8 md:flex-1`}
            >
              {list.map((row, index) => (
                <TestimonialItem key={row.id + index} testimonial={row} />
              ))}
            </div>
          ))}

          <div className="pointer-events-none absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-white"></div>
          <div className="pointer-events-none absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-white"></div>
        </div>
      </div>
    </section>
  )
}
