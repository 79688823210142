export const LinkedinIcon = props => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle opacity="0.24" cx="17" cy="17" r="16" stroke="#000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4967 13.7315C13.3228 13.7315 13.9925 13.1207 13.9925 12.3665C13.9925 11.6116 13.3228 11 12.4967 11C11.6697 11 11 11.6116 11 12.3665C11 13.1207 11.6697 13.7315 12.4967 13.7315ZM11.3429 22.5124H14.0269V14.7714H11.3429V22.5124ZM19.2182 16.4512C18.2139 16.4512 17.7939 17.3193 17.7939 18.207V22.4135H15.1143V14.6726H17.7939V15.7147C18.499 14.8043 19.1116 14.4286 20.2224 14.4286C21.3342 14.4286 23.1414 14.9556 23.1414 18.0129V22.4135H20.4694V18.1858C20.4694 17.3193 20.2224 16.4512 19.2182 16.4512Z"
      fill="black"
    />
  </svg>
)
