import React from 'react'

export function SmartForms() {
  return (
    <div className="relative bg-slate-200 py-[8vmin] px-4 md:px-0 overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-xs text-slate-500 uppercase tracking-widest flex justify-between items-center">
          <span className="flex-shrink-0">Make it smart</span>
        </h2>
        <div className="grid gap-[4vmin] grid-cols-12 items-center">
          <div className="col-span-12">
            <p className="mt-4 text-2xl lg:text-5xl font-bold text-black font-hdg leading-none">
              Create forms as smart as your ideas
            </p>
            <p className="max-w-3xl mt-6 text-xl lg:text-2xl md:text-4xl leading-normal font-normal text-slate-700 font-hdg">
              Create intelligent, interactive forms that optimize your
              workflows. Use features like AI copilot for fast form creation,
              apply dynamic conditional logic, and use variables with hidden
              fields to capture the right data. Tailor multiple outcomes based
              on responses, making every interaction smarter and more efficient.
            </p>
          </div>
        </div>

        <div className="col-span-12 w-full max-w-8xl mx-auto bg-white mt-10 lg:mt-20 rounded-lg md:rounded-xl lg:rounded-2xl overflow-hidden shadow-sm">
          <img src="/static/smart.webp" alt="Create forms with AI" />
        </div>
      </div>

      <div className="max-w-7xl mx-auto">
        <div className="mt-10 lg:mt-20 grid gap-[6vmin] grid-cols-1 md:grid-cols-12 items-start">
          <div className="col-span-6">
            <div className="w-full bg-white rounded-md">
              <img
                src="/static/features/conditional-logic.webp"
                alt="Conditional logic"
                width="1350"
                height="686"
                loading="lazy"
                className="rounded-md"
              />
            </div>
            <h3 className="mt-10 text-2xl lg:text-3xl font-bold text-black leading-none">
              Conditional logic
            </h3>
            <p className="max-w-xl mt-6 text-xl lg:text-2xl md:text-2xl leading-normal font-normal text-slate-700 font-hdg">
              Create forms that adapt to user responses in real-time with
              conditional logic. Show or hide fields, tailor questions, and
              guide users based on their answers, ensuring a personalized and
              efficient experience for every respondent.
            </p>
          </div>

          <div className="col-span-6">
            <div className="w-full bg-white rounded-md">
              <img
                src="https://s2.loli.net/2024/10/04/c8rkzH1JwpVIhWO.png"
                alt="Multiple endings"
                width="1350"
                height="686"
                loading="lazy"
                className="rounded-md"
              />
            </div>
            <h3 className="mt-10 text-2xl lg:text-3xl font-bold text-black leading-none">
              Multiple endings
            </h3>
            <p className="max-w-xl mt-6 text-xl md:text-2xl leading-normal font-normal text-slate-700 font-hdg">
              Tailor each user’s journey with multiple endings. Whether it’s
              customized messages, redirects, or next steps, you can ensure
              respondents receive relevant outcomes based on their answers,
              enhancing user engagement and improving form completion rates.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
