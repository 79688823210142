export const IconX = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M13.7053 10.6226L20.4017 3H18.8154L12.9985 9.61729L8.35594 3H3L10.0219 13.0075L3 21H4.58628L10.7252 14.0104L15.6291 21H20.985M5.1588 4.17142H7.59577L18.8142 19.8861H16.3767" />
  </svg>
)
