export const IconGithub = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 37 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill={props.color}
      d="M18.5 0C8.27875 0 0 8.02933 0 17.9426C0 25.8823 5.29562 32.5883 12.6494 34.9657C13.5744 35.1227 13.9213 34.5844 13.9213 34.1134C13.9213 33.6873 13.8981 32.2743 13.8981 30.7716C9.25 31.6015 8.0475 29.6726 7.6775 28.6634C7.46937 28.1475 6.5675 26.5551 5.78125 26.129C5.13375 25.7925 4.20875 24.9627 5.75812 24.9403C7.215 24.9178 8.25562 26.2411 8.6025 26.7794C10.2675 29.4932 12.9269 28.7307 13.9906 28.2597C14.1525 27.0934 14.6381 26.3084 15.17 25.8598C11.0538 25.4113 6.7525 23.8637 6.7525 17.0007C6.7525 15.0494 7.46938 13.4346 8.64875 12.1786C8.46375 11.73 7.81625 9.89088 8.83375 7.42377C8.83375 7.42377 10.3831 6.95277 13.9213 9.26289C15.4012 8.85918 16.9738 8.65732 18.5463 8.65732C20.1188 8.65732 21.6913 8.85918 23.1713 9.26289C26.7094 6.93035 28.2588 7.42377 28.2588 7.42377C29.2763 9.89088 28.6287 11.73 28.4438 12.1786C29.6231 13.4346 30.34 15.027 30.34 17.0007C30.34 23.8861 26.0156 25.4113 21.8994 25.8598C22.57 26.4205 23.1481 27.4971 23.1481 29.1792C23.1481 31.5791 23.125 33.5079 23.125 34.1134C23.125 34.5844 23.4719 35.1451 24.3969 34.9657C28.07 33.7641 31.2619 31.4751 33.5229 28.4212C35.7839 25.3673 37 21.7023 37 17.9426C37 8.02933 28.7213 0 18.5 0Z"
    />
  </svg>
)
