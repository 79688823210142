export const IconDribble = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M33 17.5C33.0026 19.5416 32.6006 21.5635 31.8173 23.4488C31.0339 25.3341 29.8847 27.0455 28.436 28.4841C26.9992 29.9183 25.2939 31.0554 23.4174 31.8303C21.5409 32.6051 19.5302 33.0026 17.5 33C8.93936 33 2.00001 26.0606 2.00001 17.5C1.99459 13.5256 3.52124 9.70208 6.26251 6.82438C7.70892 5.2981 9.45157 4.08301 11.3838 3.25348C13.316 2.42395 15.3972 1.99743 17.5 2.00001C19.5302 1.99738 21.5409 2.39486 23.4174 3.16973C25.2939 3.9446 26.9992 5.08166 28.436 6.51593C29.8847 7.95451 31.0339 9.6659 31.8173 11.5512C32.6006 13.4365 33.0026 15.4584 33 17.5Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.9991 17.4999C30.7377 17.4999 24.4989 16.6474 18.9151 19.0987C12.8491 21.7624 8.45722 25.8947 6.54297 28.4638"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6875 3.12695C14.1132 5.36593 19.4065 11.066 21.375 16.7251C23.3435 22.3841 24.072 29.3421 24.5215 31.3222"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.12109 15.5624C5.04904 15.7391 12.7998 15.898 17.7598 13.7799C22.7198 11.6618 26.9877 7.76591 28.4517 6.52979M3.16424 23.4C4.53003 26.706 6.99329 29.4405 10.1392 31.143"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.00001 17.5C1.99459 13.5256 3.52124 9.70208 6.26251 6.82438C7.70892 5.2981 9.45157 4.08301 11.3838 3.25348C13.316 2.42395 15.3972 1.99743 17.5 2.00001M23.7 3.28961C25.4652 4.06193 27.0712 5.15599 28.436 6.51593C29.8847 7.95451 31.0339 9.6659 31.8173 11.5512C32.6006 13.4365 33.0026 15.4584 33 17.5C33 19.408 32.6551 21.2363 32.0235 22.925M17.5 33C19.5302 33.0026 21.5409 32.6051 23.4174 31.8303C25.2939 31.0554 26.9992 29.9183 28.436 28.4841"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
