export const ArrowRightIcon = props => (
  <svg
    width="6"
    height="12"
    viewBox="0 0 6 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.55987 6.45072C5.55565 6.45493 5.54984 6.45598 5.54565 6.45996L1.33729 11.0849C1.08309 11.3338 0.673341 11.3367 0.422595 11.091C0.171837 10.8452 0.174474 10.4442 0.428923 10.195L4.23804 6.00877L0.421271 1.8144C0.167091 1.56522 0.166821 1.16179 0.420743 0.912886C0.674935 0.664238 1.0868 0.664496 1.34125 0.913414L5.5596 5.54945C5.81379 5.79836 5.81405 6.20205 5.55986 6.45071L5.55987 6.45072Z"
      fill="currentColor"
    />
  </svg>
)
