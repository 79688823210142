export const IconArrow = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 59 85"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M35.0426 3.5963C40.1892 13.0614 43.8014 25.0197 39.7361 35.5647C37.1382 42.3036 29.6875 47.4562 22.4542 47.065C15.6228 46.6956 8.8629 43.4491 7.21186 36.3859C6.35128 32.7043 7.21663 29.1132 10.897 27.4481C16.5198 24.9042 24.1341 29.1971 27.9054 33.2022C39.6747 45.7013 44.5748 66.4297 38.8984 82.7155"
      stroke="#5883A4"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M48.1836 73.7783C46.2513 76.0914 41.6778 81.2342 38.8424 83.301"
      stroke="#5883A4"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M38.8438 83.2998C37.7121 80.5063 34.9322 74.2105 32.8654 71.3751"
      stroke="#5883A4"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
)
