import { useTranslation } from '@/utils/hook'

export function Cta(): JSX.Element {
  const t = useTranslation()

  return (
    <div className="bg-blue-200/30">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <div className="">
          <h2 className="text-4xl text-slate-950 mb-2 font-bold">
            {t('product.ctaHeading')}
          </h2>
          <p className="text-slate-700 text-xl">{t('product.ctaSubheading')}</p>
        </div>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <a
              href="https://my.heyform.net/sign-up"
              className="inline-flex items-center justify-center px-6 py-3 text-lg font-medium rounded-xl text-slate-50 bg-slate-950"
            >
              {t('common.tryFree')}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
