import { RiGithubFill } from '@remixicon/react'
import Link from 'next/link'

export function OpenSourceCTA() {
  return (
    <section className="bg-slate-900 py-16 md:py-32 px-4 text-center sm:text-left">
      <div className="max-w-7xl mx-auto">
        <h2 className="sectionTitle !text-white">Dependable, every time.</h2>
        <p className="sectionDesc !text-slate-300">
          HeyForm is open source and bootstrapped, we run a profitable business
          funded 100% by our users, allowing us to grow on our own terms,
          without any external pressure or nonsense.
        </p>
        <div className="flex justify-center md:justify-start">
          <Link
            className="bg-slate-200 hover:bg-slate-100 text-black font-medium py-3 px-4 rounded-md transition duration-300 ease-in-out inline-block mt-8"
            href="https://github.com/heyform/heyform"
            target="_blank"
          >
            <RiGithubFill className="inline mr-2 h-5 w-5" />
            Check us on GitHub
          </Link>
        </div>
        <div className="mt-20 grid grid-cols-1 sm:grid-cols-3 gap-8 text-center md:text-left">
          {[
            { value: '# 1', label: 'Trending of the Day' },
            { value: '7k+', label: 'Stars' },
            { value: '100k+', label: 'Deployments' }
          ].map((stat, index) => (
            <div key={index}>
              <div className="text-7xl font-medium text-white mb-2">
                {stat.value}
              </div>
              <div className="text-2xl text-slate-400 font-medium">
                {stat.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
