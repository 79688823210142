import { useTranslation } from '@/utils/hook'
import Image from 'next/image'
import { RiArrowRightLine } from '@remixicon/react'

export function Banner({ userCount, creators }): JSX.Element {
  const t = useTranslation()

  const usersText = t('home.bannerSocialProof', {
    count: new Intl.NumberFormat().format(userCount)
  })
  const [beforeCount, afterCount] = usersText.split(
    new Intl.NumberFormat().format(userCount)
  )

  return (
    <section className="pt-8 md:pt-32 bg-slate-100">
      <div className="max-w-7xl mx-auto text-center">
        <div className="flex flex-col md:flex-row justify-center items-center px-4">
          {/* Left Section: Avatars */}
          <div className="relative overflow-x-hidden rounded-full">
            <div className="flex -space-x-3">
              {creators.slice(0, 5).map((creator, index) => (
                <Image
                  key={index}
                  className="inline-block h-12 w-12 rounded-full bg-slate-50 object-cover dark:bg-white/80"
                  src={creator.avatar}
                  width={100}
                  height={100}
                  quality={100}
                  alt={`Avatar for user ${creator.name}`}
                />
              ))}
            </div>
          </div>

          {/* Right Section: Rating and Text */}
          <div className="ml-0 md:ml-4 mt-4 md:mt-0 flex flex-col items-center md:items-start text-center md:text-left">
            {/* Star Rating */}
            <div className="flex items-center">
              <Image
                src="/static/stars-4.5.svg"
                alt="HeyForm Rating"
                quality={100}
                width={180}
                height={32}
                priority
              />
            </div>

            {/* Text */}
            <div className="flex items-center text-xs sm:text-base text-slate-800 mt-2">
              <span className="mr-1">{beforeCount}</span>
              <span className="font-bold whitespace-nowrap">
                {new Intl.NumberFormat().format(userCount)}
              </span>
              <span className="ml-1">{afterCount}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="relative text-slate-900 max-w-5xl mx-auto px-4 md:px-12 z-10">
        <div className="pt-8 md:pt-16 text-left sm:text-center">
          <h1 className="max-w-5xl mx-auto pageTitle !tracking-tight">
            {t('home.bannerHeadline1')} <br />
            <span className="text-2xl md:text-5xl font-medium text-slate-600 !tracking-normal">
              {t('home.bannerHeadline2')}
            </span>
          </h1>
        </div>
      </div>
      <div className="relative">
        <div className="max-w-7xl mx-auto mt-8 overflow-hidden relative z-10">
          <Image
            src="/static/banner.webp"
            alt="HeyForm banner"
            className="w-full shadow-sm rounded-t-lg"
            quality={100}
            width={3840} // 图片实际宽度
            height={2880} // 图片实际高度
            priority
            unoptimized
          />
        </div>
        <div className="absolute left-0 right-0 bottom-0 z-0 h-1/2 bg-slate-100"></div>
      </div>

      <div className="relative bg-white py-32 px-4 sm:px-6">
        <div className="max-w-7xl mx-auto">
          <div className="max-w-6xl text-2xl md:text-5xl text-gray-400 !leading-tight tracking-loose">
            HeyForm is a simple yet powerful software for SMEs to{' '}
            <span className="text-black font-medium">
              create engaging forms with ease
            </span>
            . It offers modern features to{' '}
            <span className="text-black font-medium">
              collect and analyze data
            </span>{' '}
            through customizable forms,{' '}
            <span className="text-black font-medium">
              integrate with various applications
            </span>
            , and simplify workflows.
          </div>
          <a
            className="text-black font-medium text-xl lg:text-4xl inline-block mt-8 underline"
            href="https://my.heyform.net/sign-up"
          >
            {t('common.tryFree')}
            <RiArrowRightLine className="ml-2 inline w-8" />
          </a>
        </div>
      </div>
    </section>
  )
}
