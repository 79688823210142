export const IconTwitter = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 30 28"
    fill={props.color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M23.6145 0H28.1928L18.1928 11.8844L30 28H20.7229L13.494 18.2311L5.18072 28H0.60241L11.3253 15.3067L0 0H9.51807L16.0843 8.96L23.6145 0ZM21.988 25.1378H24.5181L8.13253 2.67556H5.36145L21.988 25.1378Z" />
  </svg>
)
